import React, { useState, useEffect, useRef } from 'react';

import SubCategoriesCard from '../../components/product/subcategories-cards/SubCategoriesCard';
import CategoriesCard from '../../components/product/categories-cards/CategoriesCard';
import Pagination from 'react-bootstrap/Pagination';


import ProductHeroImage from '../../assets/images/prudct-hero_img.png';
import { ToastContainer, toast } from 'react-toastify'


import style from './product.module.css';
import ProductCard from '../../components/product-card/ProductCard';
import airfilter from '../../assets/images/air-filter.png';
import hydra1 from '../../assets/images/hydra1.png';
import braks from '../../assets/images/braks.png';
import control from '../../assets/images/control.png';
import oil_filter from '../../assets/images/oil-filyer.png';
import hydro from '../../assets/images/hydro.png';
import ball from '../../assets/images/ball.png';
import eva from '../../assets/images/eva.png';
import { useTranslation } from "react-i18next";
import HeroImage from '../../components/hero-image/HeroImage';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReactSelect2Wrapper from 'react-select2-wrapper';
import Select from 'react-select'
import BottomHeroImage from '../../assets/images/CustomerReviewsbottomHero.png'

import { useSearchParams, useNavigate } from 'react-router-dom';
import axios, { all } from 'axios';
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { API_BASE_URL } from '../../config/config';


import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import Loader from '../../components/loader/Loader';
import LoaderStyle from '../../components/loader/loader.module.css'
import { Height } from '@mui/icons-material'
import { capitalizeWords, updateCartCount } from '../../utils/commonFunctions';
import NodDataImg from '../../assets/images/datanotfoundImg.png'
import { ThreeDots } from 'react-loader-spinner'
import BottomHero from '../../components/bottom-hero/BottomHero';
import { ClipLoader } from 'react-spinners';

export default function SimpleAccordion(props) {
    const { t } = useTranslation();

    // const API_BASE_URL = `https://admin-anycarpart.go-demo.com/api/v1`;
    // const API_BASE_URL = `http://localhost:3003/api/v1`;
    const imageURL = `https://admin-anycarpart.go-demo.com`;
    const fetchConfig = getFetchConfig();

    const [loader, setLoader] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const categoryId = searchParams.get('categoryId');
    const subCategoryId = searchParams.get('subCategoryId');

    const [searchFilter, setSearchFilter] = useState("");
    const [vinFilter, setVinFilter] = useState("");
    const [minPriceFilter, setMinPriceFilter] = useState("");
    const [maxPriceFilter, setMaxPriceFilter] = useState("");
    const [dateFilter, setDateFilter] = useState("");
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [menuCategories, setMenuCategories] = useState([]);
    const [productByFilter, setProductByFilter] = useState([]);
    const [menuFilter, setMenuFilter] = useState([]);
    const [originalProductByFilter, setOriginalProductByFilter] = useState([]);
    const [Brands, setBrands] = useState([])
    const [selecteBrandOtion, setselecteBrandOtion] = useState('');
    const [selectedOption, setselectedOption] = useState([])
    const [selectedoptionmdelvalue, setselectedoptionmdelvalue] = useState()
    const [year, setYear] = useState([])
    const [yearValue, setYearvalue] = useState()
    const chatWindowRef = useRef(null);
    const [preautoNumber, setPreautoNumber] = useState();
    const [Subcategory, setSubcategory] = useState([]);
    const [loader1, setLoader1] = useState(false)
    const [partsNumber, setpartsNumber] = useState()
    const [vinNumber, SetVinNUmber] = useState()
    const [productData, setProductData] = useState();  
    const [promotionDiscount, setPromotionDiscount] = useState(null);

    const navigate = useNavigate();
    const url = window.location.href
    const newurl = new URL(url);
    const priautoNumbers = [];
    const oe_number = []
    const title = []
    const seachPartsHanler = (event) => {
        event.preventDefault()
        SetVinNUmber('')
        setLoader1(true)
        axios.get(`${API_BASE_URL}/product/seachproduct?priautoNumber=${partsNumber}`)
            .then((response) => {
                if (response.data.status == 400) {
                    toast.error(response.data.data.message)
                    console.log("gadsjjjjjjjjj", response.data.data)
                }
                if (response.data.data.message === "Sorry, we couldn’t find any products associated with the oe number you provided.") {
                    console.log('sjfkdfdsjsdfk')
                    navigate(`/special-order?oe_number=${partsNumber}`);
                }
                setLoader1(false)
                if (response.data.data.product.length <= 0) {
                    toast.error('Sorry, we couldn’t find any products associated with the Priauto number you provided.')
                }
                // setProductData(response.data.data.product)
                setProductByFilter(response.data.data.product);
                // let queryString = `?&categoryId=${response.data.data.subcategory[0].categoryId}`;
                // response.data.data.subcategory.forEach((number, index) => {
                //   queryString += `&subCategoryId[${index}]=${number._id}`
                // })
                // const isAlphabeticPattern = /^[a-zA-Z]+$/;
                // if (isAlphabeticPattern.test(partsNumber)) {
                //   queryString += `&title[0]=${partsNumber}`;
                // } else {
                //   queryString += `&oe_number[0]=${partsNumber}`;
                // }
                // navigate({
                //   pathname: '/product',
                //   search: `${queryString}`,
                // });
                // window.location.reload()

            })
            .catch((error) => {
                console.log(error.message, "kkkkkkkkkk")
                // toast.error("Something went wrong")
            })

    }
    // let Subcategory = []
    const ly_car_ids = []
    if (newurl.searchParams.has('priauto_number[0]')) {
        let i = 0;

        while (newurl.searchParams.has(`priauto_number[${i}]`)) {
            priautoNumbers.push(newurl.searchParams.get(`priauto_number[${i}]`));
            i++;
        }
    }

    if (newurl.searchParams.has('oe_number[0]')) {
        let i = 0;

        while (newurl.searchParams.has(`oe_number[${i}]`)) {
            oe_number.push(newurl.searchParams.get(`oe_number[${i}]`));
            i++;
        }
    }
    if (newurl.searchParams.has('title[0]')) {
        let i = 0;

        while (newurl.searchParams.has(`title[${i}]`)) {
            title.push(newurl.searchParams.get(`title[${i}]`));
            i++;
        }
    }

    if (newurl.searchParams.has('ly_car_ids[0]')) {
        let i = 0;

        while (newurl.searchParams.has(`ly_car_ids[${i}]`)) {
            ly_car_ids.push(newurl.searchParams.get(`ly_car_ids[${i}]`));
            i++;
        }
    }


    const productsPerPage = 6;

    const [currentPage, setCurrentPage] = useState(1);
    const [productsLoaded, setProductsLoaded] = useState(false);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = productByFilter ? productByFilter.slice(indexOfFirstProduct, indexOfLastProduct) : []
    const [loading, setLoading] = useState(false);
    const [noProductFound, setNoProductFound] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            if (currentProducts.length === 0) {
                setNoProductFound(true);
            }
        }, 10000);
    }, []); // 
    useEffect(() => {
        if (currentProducts.length > 0) {
            setProductsLoaded(true);
        }
    }, [currentProducts]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            window.scrollTo(0, 1100);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 1100);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 1100);
        }
    };





    const totalPages = Math.ceil(productByFilter.length / productsPerPage);


    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }


    const fetchCategory = () => {
        if (props.category.length > 0) {
            setCategories(props.category,);

        } else if (categories.length == 0) {
            setCategories([]);
            fetch(`${API_BASE_URL}/category`, {
                ...fetchConfig,
                method: `GET`,
            })
                .then(handleFetchErrors)
                .then((categoryData) => {
                    console.log(categoryData.data.category, "hhhhhhhhhhhhhhhh")
                    setCategories(categoryData.data.category);

                    setLoader(false);

                })
                .catch(standardFetchHandlers.error);

        }

    }
    const fetchActivePromotion = async () => {
        //setLoadingPromotion(true);
        try {
          const response = await fetch(`${API_BASE_URL}/promotions`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
         
          if (data && data.data.promotionDiscount) {
            console.log(data.data.promotionDiscount,'data.promotion.discount show heeeeeee.pllllllllllese follow this like s page')
            setPromotionDiscount(data.data.promotionDiscount); // Assuming the active promotion is stored in 'data.promotion'
          }
        } catch (error) {
          console.error('Error fetching active promotion:', error);
        } finally {
          //setLoadingPromotion(false);
        }
      };

    const fetchSubCategories = () => {

        fetch(`${API_BASE_URL}/category/subcategory/${categoryId}`, {
            ...fetchConfig,
            method: `GET`
        })
            .then(handleFetchErrors)
            .then((dataSubcategory) => {
                console.log(dataSubcategory.data.subCategories, "categoryData.data.subCategories")
                setSubCategories(dataSubcategory.data.subCategories);

            })
            .catch(standardFetchHandlers.error);

    };

    const fetchMenuItems = () => {

        fetch(`${API_BASE_URL}/menu/`, {
            ...fetchConfig,
            method: `GET`
        })
            .then(handleFetchErrors)
            .then((dataSubcategory) => {

                setMenuCategories(dataSubcategory.data.menu[0].subMenuData);


            })
            .catch(standardFetchHandlers.error);

    };


    const [iDs, updateIds] = useState([]);
    const [iD2, updateId2] = useState([]);




    const fetchCategoryies = () => {
        fetch(`${API_BASE_URL}/category`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((categoryData) => {
                console.log(categoryData.data.category, "hhhhhhhhhhhhhhhh")
                setCategories(categoryData.data.category);

                setLoader(false);

            })
            .catch(standardFetchHandlers.error);
    }

    const handleSubCategory = (id, event) => {
        const array = []
        const isChecked = event.target.checked;
        if (isChecked) {

            setSubcategory([...Subcategory, id]); // Add the ID to the Subcategory array
        } else {
            setSubcategory(Subcategory.filter(item => item !== id)); // Remove the ID from 
        }



        //   console.log(event.target.checked)
        //   if(event.target.checked===false){
        //     console.log(Subcategory,"Subcategory clall")
        //     Subcategory= Subcategory.filter(item => item !== id);
        //   }
        // let updatedIds;
        // console.log(id)
        // if (iDs.includes(id)) {
        //     updatedIds = iDs.filter(subId => subId !== id);
        //     console.log("hh")
        // } else {
        //     updatedIds = [...iDs, id];
        // }
        // console.log(updatedIds, "hhhhhhhhhhhhhhhhhh")
        // //   debugger

        // if (updatedIds.length === 0) {
        //     updatedIds = subCategories.map(subCategory => subCategory._id);
        // }
        // console.log(updatedIds, "yyyyyyyyyyyyyyy")

        // updateIds(updatedIds);
        // navigate({
        //     pathname: '/product',
        //     search: `?categoryId=${categoryId}&subCategoryId[0]=${id}`,
        // });
    };

    const fetchPrducts = () => {
        setLoader(true)
        const priautoNumberQueryParams = priautoNumbers.map((number, index) => `priautoNumbers[${index}]=${number}`).join('&');
        const subcategoryNumberQueryParams = Subcategory.map((number, index) => `subcategory[${index}]=${number}`).join('&');
        const oe_numberQueryParams = oe_number.map((number, index) => `oe_number[${index}]=${number}`).join('&');
        const title_QueryParams = title.map((number, index) => `title[${index}]=${number}`).join('&');

        const queryParams = [priautoNumberQueryParams, subcategoryNumberQueryParams, oe_numberQueryParams, title_QueryParams].filter(Boolean).join('&');

        const url = queryParams ? `${API_BASE_URL}/product?${queryParams}` : `${API_BASE_URL}/product`;


        if(queryParams){
            fetch(url, {
                ...fetchConfig,
                method: 'POST',
                body: JSON.stringify({
                    subcategoryId: iDs,
                    productName: searchFilter,
                    vinNumber: vinFilter,
                    date: dateFilter,
                    minPrice: minPriceFilter,
                    maxPrice: maxPriceFilter,
                    limit: 20,
                }),
            })
                .then(handleFetchErrors)
                .then((dataProduct) => {
                    console.log(dataProduct, " debugger debugger debugger")
                    if (dataProduct.status = 204) {
                        setProductByFilter([])
                        setLoader(false);
                    }
    
    
                    setProductByFilter(dataProduct.data.product.flat());
    
                    setLoader(false);
                })
                .catch(standardFetchHandlers.error);
        };
        }



    const handlesearchfilter = (event) => {
        setSearchFilter(event.target.value);
    };

    const handleVinfilter = (event) => {
        setVinFilter(event.target.value);
    };
    const handleDateFilter = (event) => {
        // alert(event.target.value);
        setDateFilter(event.target.value);
    };
    const handlePriceShortFilter = (min, max) => {
        setMinPriceFilter(min);
        setMaxPriceFilter(max);
    };
    const ChangeCategory = (id) => {
        const ly_car_idsQueryParams = ly_car_ids.map((number, index) => `ly_car_ids[${index}]=${number}`).join('&');
        let vinNumber = new URLSearchParams(window.location.search).get("vinnumber"); // Get the vinnumber from the current URL

        fetch(`${API_BASE_URL}/category/subcategory/${id}?${ly_car_idsQueryParams}`, {
            ...fetchConfig,
            method: 'GET',
        })
            .then(handleFetchErrors)
            .then((dataSubcategory) => {
                let queryString = `?categoryId=${id}`;

                if (vinNumber) {
                    queryString += `&vinnumber=${vinNumber}`; // Make sure to use 'vinnumber' instead of 'vinNumber'
                }

                if (dataSubcategory && dataSubcategory.data.subcategory) {
                    const data = dataSubcategory.data.subcategory.map((data) => data._id)
                    setSubcategory(data)
                    dataSubcategory.data.subcategory.forEach((subcategory, index) => {
                        queryString += `&subCategoryId[${index}]=${subcategory._id}`;
                    });
                }

                if (dataSubcategory && dataSubcategory.data.subCategories) {
                    const data = dataSubcategory.data.subCategories.map((data) => data._id)
                    setSubcategory(data)
                    dataSubcategory.data.subCategories.forEach((subcategory, index) => {
                        queryString += `&subCategoryId[${index}]=${subcategory._id}`;
                    });
                }

                if (dataSubcategory && dataSubcategory.data.PRIAUTO_Number) {
                    dataSubcategory.data.PRIAUTO_Number.forEach((number, index) => {
                        queryString += `&priauto_number[${index}]=${number}`;
                    });
                }

                if (ly_car_ids && ly_car_ids.length > 0) {
                    ly_car_ids.forEach((number, index) => {
                        queryString += `&ly_car_ids[${index}]=${number}`;
                    });
                }

                navigate({
                    pathname: '/product',
                    search: queryString, // Use the 'queryString' variable to set the search parameter
                });
            })
            .catch(standardFetchHandlers.error);
    };


    //    useEffect(()=>{  
    //      fetchSubCategories();
    //      fetchPrducts();
    //    },[iDs])

        useEffect(() => {
            
            fetchActivePromotion() ; 
        }, [])


    const handleSelectAll = () => {
        if (iDs.length === subCategories.length) {
            return; // Do nothing if all checkboxes are already selected
        } else {
            const allSubCategoryIds = subCategories.map(subCategory => subCategory._id);
            updateIds(allSubCategoryIds);
        }
    };


    // const isAllSelected = iDs.length === subCategories.length;

    const handleSortChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue === "lowToHigh") {
            const sortedProducts = [...productByFilter].sort((a, b) => a.price - b.price);
            setProductByFilter(sortedProducts);
        } else if (selectedValue === "highToLow") {
            const sortedProducts = [...productByFilter].sort((a, b) => b.price - a.price);
            setProductByFilter(sortedProducts);
        }
        else if (selectedValue === "aToZ") {
            const sortedProducts = [...productByFilter].sort((a, b) => a.title.localeCompare(b.title));
            setProductByFilter(sortedProducts);
        }
        else if (selectedValue === "zToA") {
            const sortedProducts = [...productByFilter].sort((b, a) => a.title.localeCompare(b.title));
            setProductByFilter(sortedProducts);
        }
        else if (selectedValue === "default") {
            const sortedProducts = [...productByFilter].sort((a, b) => a._id.localeCompare(b._id));
            setProductByFilter(sortedProducts);
        }
    }
    const handleDateSort = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "latest") {
            const sortedProducts =  [...productByFilter].sort((a, b) => a.createdAt.localeCompare(b.createdAt));
            setProductByFilter(sortedProducts);
        } else if (selectedValue === "oldest") {
            const sortedProducts = [...productByFilter].sort((b, a) => a.createdAt.localeCompare(b.createdAt));
            setProductByFilter(sortedProducts);
        }
        else if (selectedValue === "default1") {
            const sortedProducts = [...productByFilter].sort((a, b) => a._id.localeCompare(b._id));
            setProductByFilter(sortedProducts);
        }
    }
    const handleFilterData = () => {
        if (selecteBrandOtion && selectedoptionmdelvalue && year && year.length > 0) {
            const brands = selecteBrandOtion.value;
            const model = selectedoptionmdelvalue.value;
            const sop = year;
            axios
                .get(`${API_BASE_URL}/product/getdata?brand=${brands}&modelNumber=${model}&sop=${`[${sop}]`}`)
                .then((data) => {
                    //   console.log(data,"hello code")
                    if (data.data.status === 400) {
                        toast.error(data.data.data.message);
                    }

                    setCategories(data.data.data.Obj.Category);
                    setSubCategories(data.data.data.Obj.subcategory);
                    //   setProductByFilter(data.data.data.Obj.product);

                    let queryString = `?&categoryId=${data.data.data.Obj.Category[0]._id}`;
                    data.data.data.Obj.subcategory.forEach((number, index) => {
                        const game = data.data.data.Obj.subcategory.map((data) => data._id)
                        console.log(game)
                        setSubcategory(game)
                        queryString += `&subCategoryId[${index}]=${number._id}`;
                    });
                    data.data.data.Obj.PRIAUTO_Number.forEach((number, index) => {
                        queryString += `&priauto_number[${index}]=${number}`;
                    });
                    data.data.data.Obj.oe_number.forEach((number, index) => {
                        queryString += `&oe_number[${index}]=${number}`;
                    });
                   
                    data.data.data.Obj.ly_car_id.forEach((number, index) => {
                        queryString += `&ly_car_ids[${index}]=${number.ID}`;
                    })



                    //console.log(queryString, "helllo query string")
                    navigate({
                        pathname: '/product',
                        search: `${queryString}`,
                    });
                    //    window.location.reload()
                })
                .catch((err) => {
                    console.log(err, "jjjjjj");
                });
        } else {
            toast.error("Please Select Make, Model & Year");
        }
    };
    const [Models, setModels] = useState([]);
    const [selectedOptionModelValue, setselectedOptionModelValue] = useState(null);


    const ModelNumberHandler = () => {
        if (selecteBrandOtion) {
            const apiUrl = `${API_BASE_URL}/product/modeldata?brand=${selecteBrandOtion.value}`;

            const axiosConfig = {
                headers: { 'Content-Type': 'application/json' },
            };

            // Make the GET request to the API
            axios
                .get(apiUrl, axiosConfig)
                .then((response) => {
                    // Handle the successful response here
                    const formattedOptions = response.data.data.data[0].uniqueModels.map((model) => ({
                        value: model,
                        label: model,
                    }));
                    setModels(formattedOptions);
                })
                .catch((error) => {
                    // Handle any errors here
                    console.error(error.message, '+++++++++++++++++++++++++++++++++++++++++++++++');
                });
        }
    }

    const Autoselectedvalue = (data) => {
        setselectedoptionmdelvalue(data);
        setYearvalue('');
        setYear([]);
    }

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Preload options when the component mounts
        preloadOptions();
    }, []);

    const preloadOptions = () => {
        setIsLoading(true);
        const apiUrl = `${API_BASE_URL}/product/manufaturedata`;
        const axiosConfig = { headers: { 'Content-Type': 'application/json' } };

        // Make the GET request to the API
        axios
            .get(apiUrl, axiosConfig)
            .then((response) => {
                const formattedOptions = response.data.data.data.map((model) => ({
                    value: model.uniqueBrands,
                    label: model.uniqueBrands,
                }))
                setBrands(formattedOptions);
                setIsLoading(false);
            })
            .catch((error) => {
                // Handle any errors here
                console.error(error.message, '+++++++++++++++++++++++++++++++++++++++++++++++');
                setIsLoading(false);
            });
    }

    const SelectBrandhandler = (data) => {
        setselecteBrandOtion(data);
        setselectedoptionmdelvalue('');
        setYearvalue('');
        setYear([]);
        setselectedOption([]);
    }
    const autoSelectedYear = (value) => {
        if (value && value.length > 0) {
            const year = value.map((data) => data.year);
            setYear(year)
        }
    }




    console.log(props, "propsprops")

    const resetfilter = () => {
        props.setsubcategory([]) // Assuming setCategories is a setter function for the 'categories' prop
        props.setMessage("reset");
        setLoader(false);
        setselectedoptionmdelvalue('');
        setYearvalue('');
        setYear([]);
        setselectedOption([]);
        setBrands([]);
        setselecteBrandOtion('');
        fetchCategory()
        fetch(`${API_BASE_URL}/category`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((categoryData) => {
                setCategories(categoryData.data.category)
                setLoader(false);
                fetchSubCategories()

                let queryString = `?&categoryId=${categoryData.data.category[0]._id}`;
                navigate({
                    pathname: '/product',
                    search: `${queryString}`,
                });
                window.location.reload()
            })
            .catch(standardFetchHandlers.error);
    }


    // useEffect(() => {
    //     fetchCategory();
    //     fetchSubCategories();
    //     fetchPrducts();
    // }, []);

    // useEffect(() => {

    //     fetchPrducts();
    //     fetchCategory();
    //     fetchSubCategories();
    // }, [Subcategory])


    useEffect(() => {
        fetchPrducts();
        // fetchCategory();
        // fetchSubCategories();
    }, [minPriceFilter, maxPriceFilter]);


    useEffect(() => {
        // Assume 'newurl' is an instance of URL or URLSearchParams containing your URL parameters
        if (newurl.searchParams.has('subCategoryId[0]')) {
            let i = 0;
            const updatedSubcategories = [];

            while (newurl.searchParams.has(`subCategoryId[${i}]`)) {
                updatedSubcategories.push(newurl.searchParams.get(`subCategoryId[${i}]`));
                i++;
            }

            // Use the setSubcategory function to update the state
            setSubcategory(updatedSubcategories);

        }
    }, []);


    useEffect(() => {
        const categoryId = searchParams.get('categoryId');
        const vin_number = searchParams.get('vinnumber')
        if (Subcategory || Subcategory.length > 0) {
            const queryParamCount = newurl.searchParams.toString().split('&').length - 1;
            console.log(Subcategory, "bhhhhh") // Subtract 1 to account for the initial '?'
            const subCategoryParams = new Map();
            newurl.searchParams.forEach((value, key) => {
                if (key.startsWith('subCategoryId[')) {
                    subCategoryParams.set(key, value);
                }
            });

            Subcategory.forEach((number, index) => {
                subCategoryParams.set(`subCategoryId[${index}]`, number);
            });
            const subCategoryKeysToRemove = Array.from(subCategoryParams.keys()).filter((key) => {
                const index = Number(key.match(/\d+/)[0]);
                return index >= Subcategory.length;
            });
            subCategoryKeysToRemove.forEach((key) => {
                subCategoryParams.delete(key);
            });


            let queryString = `?&categoryId=${categoryId}`;
            if (vin_number) {
                queryString += `&vinnumber=${vin_number}`
            }
            if (ly_car_ids) {
                ly_car_ids.forEach((number, index) => {
                    queryString += `&ly_car_ids[${index}]=${number}`;
                });
            }

            subCategoryParams.forEach((value, key) => {
                queryString += `&${key}=${value}`;
            });

            if (priautoNumbers) {
                priautoNumbers.forEach((number, index) => {
                    queryString += `&priauto_number[${index}]=${number}`;
                });
            }

            if (oe_number) {
                oe_number.forEach((number, index) => {
                    queryString += `&oe_number[${index}]=${number}`;
                })
            }
            if (title) {
                title.forEach((number, index) => {
                    queryString += `&title[${index}]=${number}`;
                })
            }
            fetchPrducts();
            // fetchCategory();
            // fetchSubCategories();



            //    if (Subcategory.length === 0) {

            //      resetfilter()
            //  }


            navigate({
                pathname: '/product',
                search: queryString,
            });
        }
    }, [Subcategory]);





    //  Select all items by default
    useEffect(() => {
        if (subCategories.length > 0) {
            const allSubCategoryIds = subCategories.map(subCategory => subCategory._id);
            updateIds(allSubCategoryIds);
        }
    }, [subCategories]);

    useEffect(() => {
        setOriginalProductByFilter(productByFilter);
    }, [productByFilter]);



    useEffect(() => {
        fetchCategory()
        if (props.category.length > 0) {

            setCategories(props.category)

        }
        // setCategories([])
        scrollToBottom()
        fetchSubCategories();
        fetchPrducts()
    }, [categoryId])



    // useEffect(() => {
    //     if (props.productdata.length > 0) {
    //         setProductByFilter(props.productdata)
    //     }
    // }, [props.productdata])


    useEffect(() => {
        if (props.data != undefined) {
            let label1 = {
                value: props.data?.Brand,
                label: props.data?.Brand
            }
            let label3 = {
                value: `${props.data?.SOP}-${props.data?.EOP}`,
                label: `${props.data?.SOP}-${props.data?.EOP}`
            }
            let label2 = {
                value: props.data?.Models,
                label: props.data?.Models
            }
            setselecteBrandOtion(label1)
            setYearvalue(label3)
            setselectedoptionmdelvalue(label2)


        }
    }, [props.data])


    useEffect(() => {
        if (props.category.length > 0) {
            setCategories(props.category)
        } else {
            setCategories([])
        }
    }, [props.category])

    function scrollToBottom() {
        chatWindowRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        scrollToBottom()
    }, [])


    return (
        <>
            {
                categories && categories.length ?
                    categories.map((allCategory) => {
                        if (allCategory._id == categoryId) {
                            return (
                                <HeroImage HeroImage={ProductHeroImage} Heading={allCategory.title} />
                            )
                        }
                    })
                    : <HeroImage HeroImage={ProductHeroImage} Heading={<div id="fountainG">
                        <div id={LoaderStyle.fountainG_1} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_2} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_3} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_4} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_5} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_6} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_7} className={LoaderStyle.fountainG}></div>
                        <div id={LoaderStyle.fountainG_8} className={LoaderStyle.fountainG}></div>
                    </div>} />

            }

            <div className={style.main_wrapper}>


                <section className={style.subcategories_product_card_section}>
                    {
                        categories && categories.length ?
                            categories.map((allCategory) => {
                                return (

                                    // <div className="" onClick={() => { handleCategory(allCategory._id) }}>
                                    allCategory._id === categoryId ?
                                        <div className={style.clickCategory} >

                                            <CategoriesCard activeStyle={{
                                                filter: "brightness(1000%) grayscale(100%) opacity(0.1) drop-shadow(0 0 0 #f86b1f) drop-shadow(0 0 0 #f86b1f) drop-shadow(0 0 0 #f86b1f) drop-shadow(0 0 0 #f86b1f) drop-shadow(0 0 0 #f86b1f)"
                                            }}
                                                categoryId={allCategory._id}
                                                title={capitalizeWords(allCategory.title)}
                                                imageURL={allCategory.image}
                                            />
                                        </div>
                                        :
                                        <div className={style.clickCategory} onClick={() => ChangeCategory(allCategory._id)}>

                                            <CategoriesCard defaultStyle={{
                                                filter: "brightness(1000%) grayscale(100%) opacity(0.1) drop-shadow(0 0 0 #212f63) drop-shadow(0 0 0 #212f63) drop-shadow(0 0 0 #212f63) drop-shadow(0 0 0 #212f63) drop-shadow(0 0 0 #212f63)"
                                            }}
                                                categoryId={allCategory._id}
                                                title={capitalizeWords(allCategory.title)}
                                                imageURL={allCategory.image}
                                            />
                                        </div>

                                )
                            })
                            : loader ?
                                <>
                                    {/* <div className={style.loaderStyle}> */}
                                        {/* <Loader /> */}
                                        <div style={{textAlign:'center', paddingLeft:'650px'}}>
            <ClipLoader color="#212F63" size={50} />
          
        {/* </div> */}
                                    </div>
                                </> : null
                    }

                </section>
                <div className={style.select_boxes_lists} >

                    <div className={style.select_product}>
                        <Select
                            isLoading={isLoading}
                            onChange={SelectBrandhandler}
                            value={selecteBrandOtion}
                            options={Brands}
                            placeholder={isLoading ? "Loading..." : "Please Select To Make"}
                        />
                    </div>

                    <div className={style.select_product}>
                        <Select
                            onMenuOpen={ModelNumberHandler} // Trigger ModelNumberHandler when menu is opened
                            onChange={Autoselectedvalue}
                            value={selectedoptionmdelvalue}
                            options={Models}
                            placeholder="Please Select Model Number"
                        />
                    </div>
                    <div className={style.select_product} >

                        <DatePicker
                            style={{ border: "1px solid hsl(0, 0%, 80%)", height: "40px" }}
                            onlyYearPicker
                            multiple
                            sort
                            onChange={autoSelectedYear} // Attach the event handler
                            //  plugins={[
                            //      <DatePanel />
                            //  ]}
                            placeholder='Please Select Year'
                        />

                    </div>
                    <div>
                        <button className='bttonApply' style={{ position: "relative", bottom: "4px", padding: "6px 128px" }} onClick={() => { handleFilterData(selecteBrandOtion, selectedoptionmdelvalue, yearValue) }}>
                            {t('ProductApply')}
                        </button>
                    </div>
                </div>

                <div className={style.subscribe_cateory}>

                    <div className={style.select_box_wraper_sorting}>

                        <div style={{ border: "1px solid #e1e1e1" }}>
                            <select name="sorting" id="" onChange={handleSortChange}>
                                <option value="default">All Products</option>
                                <option value="lowToHigh">Price: Low to High</option>
                                <option value="highToLow">Price: High to Low</option>
                                <option value="aToZ">Sort A to Z</option>
                                <option value="zToA">Sort Z to A</option>
                            </select>
                        </div>

                        <div style={{ border: "1px solid #e1e1e1", marginLeft: '30px' }}>
                            <select name="sorting1" id="" onChange={handleDateSort}>
                                <option value="default1">Sort by Date added</option>
                                <option value="latest">Latest </option>
                                <option value="oldest">Oldest</option>

                            </select>
                        </div>
                    </div>





                </div>

                {/* filter-section-start */}

                <section className={style.filter_section}>



                    <div className={style.products_items_boxes_lists}>

                        <div className={style.products_items_filters}>
                            <div className={style.serach_bar_box} >
                                <h3>
                                    {t('ProductRefineSearch')}:
                                    <button className={style.resetbuttonapply} onClick={resetfilter} >Reset</button>
                                </h3>
                            </div>
                            <div className={style.serach_bar_box}>
                                <h3>Refine Your Search By:</h3>
                                <div className="searchbar-box">
                                    <form className="search-box" onSubmit={seachPartsHanler}>

                                        <input
                                            type="search"
                                            name="partsNumber"
                                            placeholder= 'Search Products'
                                            id="search-input" value={partsNumber}
                                            onChange={(event) => { setpartsNumber(event.target.value) }} />
                                        {
                                            loader1 ? (
                                                <ThreeDots
                                                    height="40"
                                                    width="40"
                                                    radius="9"
                                                    color="#4fa94d"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" type='submit' onClick={seachPartsHanler}>
                                                    <g clip-path="url(#clip0_1_6090)">
                                                        <path d="M13.6717 12.4628L16.8839 15.6743L15.8227 16.7355L12.6112 13.5233C11.4162 14.4812 9.92994 15.0022 8.39844 15C4.67244 15 1.64844 11.976 1.64844 8.25C1.64844 4.524 4.67244 1.5 8.39844 1.5C12.1244 1.5 15.1484 4.524 15.1484 8.25C15.1506 9.7815 14.6296 11.2678 13.6717 12.4628ZM12.1672 11.9063C13.119 10.9274 13.6506 9.61532 13.6484 8.25C13.6484 5.349 11.2987 3 8.39844 3C5.49744 3 3.14844 5.349 3.14844 8.25C3.14844 11.1503 5.49744 13.5 8.39844 13.5C9.76376 13.5022 11.0759 12.9706 12.0547 12.0188L12.1672 11.9063Z" fill="#212F63" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_6090">
                                                            <rect width="18" height="18" fill="white" transform="translate(0.148438)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            )
                                        }

                                    </form>
                                </div>
                            </div>


                            <div className={style.Accordion_list}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                    >

                                        <h3>{t('ProductSubcat')}</h3>

                                    </AccordionSummary>
                                    <AccordionDetails style={{ flexDirection: 'column' }}>

                                        {
                                            subCategories.map((allSubCategoryIds, index) => {
                                                return (
                                                    <div className={style.checkbox_container} key={allSubCategoryIds._id}>
                                                        <input
                                                            type="checkbox"
                                                            name="subCategories"
                                                            checked={Subcategory.includes(allSubCategoryIds._id)}
                                                            id={allSubCategoryIds._id}
                                                            value={allSubCategoryIds._id}
                                                            onChange={(event) => {
                                                                handleSubCategory(allSubCategoryIds._id, event);
                                                            }}
                                                        />
                                                        <label htmlFor={allSubCategoryIds._id}>{capitalizeWords(allSubCategoryIds.title)}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className={style.Accordion_list}>
                                {/* <h3>{t('ProductPrice')}<i class="fa-solid fa-plus"></i></h3> */}
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}

                                    >
                                        <h3>{t('ProductPrice')}</h3>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ flexDirection: 'column' }}>
                                        <div className={style.price_list}>
                                            <p className={minPriceFilter == `` ? style.activePrice : ``} onClick={() => { handlePriceShortFilter(``, ``) }}>All</p>
                                            <p className={minPriceFilter == `0` ? style.activePrice : ``} onClick={() => { handlePriceShortFilter(`0`, `500`) }}>Under $500</p>
                                            <p className={minPriceFilter == `500` ? style.activePrice : ``} onClick={() => { handlePriceShortFilter(`500`, `1000`) }}>$500 to $1000</p>
                                            <p className={minPriceFilter == `1000` ? style.activePrice : ``} onClick={() => { handlePriceShortFilter(`1000`, `2000`) }}>$1000 to $2000</p>
                                            <p className={minPriceFilter == `2000` ? style.activePrice : ``} onClick={() => { handlePriceShortFilter(`2000`, `5000`) }}>$2000 to $5000</p>
                                            <p className={minPriceFilter == `5000` ? style.activePrice : ``} onClick={() => { handlePriceShortFilter(`5000`, `500000`) }}>$5000 and Above</p>
                                        </div>
                                        
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </div>
                        <div className={style.dropdown_lists}>
                            <div className={style.dropdown_lists1} >

                            </div>
                            <div className={style.ProductCardsectt} ref={chatWindowRef}>


                                {loading ? (
                                    // <Loader />
                                    <div style={{ margin: "50% 85%" }}>
                                    <ClipLoader color="#212F63" size={50} />
                                  </div>
                                ) : (
                                    <>

                                        {currentProducts.length === 0 && noProductFound ? (

                                            <div style={{
                                                // display: 'block',         
                                                justifyContent: 'center',
                                                alignItems: 'center'

                                            }}>
                                                <img src={NodDataImg}
                                                    style={{ height: '500px', width: '500px', objectFit: 'contain', marginLeft: '20%', marginTop: '20%' }}
                                                    alt="No Product Found" />
                                            </div>
                                        ) : (
                                            currentProducts.map((allproductByFilter) => (
                                                <>
                                                    {console.log("allproductByFilter", allproductByFilter)}
                                                    <ProductCard
                                                        key={allproductByFilter._id}
                                                        productId={allproductByFilter._id}
                                                        subcategoryId={allproductByFilter.subcategoryId}
                                                        productThumbnailShort={allproductByFilter?.thumbnail ? allproductByFilter?.thumbnail[0] : ""}
                                                        productTitle={capitalizeWords(allproductByFilter.title)}
                                                        productCurrency={allproductByFilter.currency}
                                                        productPrice={allproductByFilter.price}
                                                        promotionPrice={(allproductByFilter.price*promotionDiscount)/100}
                                                        productSku={allproductByFilter.sku}
                                                        vinNumber={allproductByFilter.vin_number}
                                                        product={allproductByFilter}


                                                        setcartCount={props.setcartCount}
                                                        cartCount={props.cartCount}
                                                        cartWishCount={props.cartWishCount}
                                                        setCartWishCount={props.setCartWishCount}
                                                    /></>
                                            ))
                                        )}
                                    </>
                                )}
                                {console.log("dsadsadasd", props.setcartCount, props.cartCount)}
                            </div>


                            <br />
                            <br />

                            {productByFilter.length > productsPerPage && (
                                <div className={style.paginationiner}>
                                    <Pagination
                                        itemsPerPage={productsPerPage}
                                        totalItems={productByFilter.length}
                                        onPageChange={handlePageChange}
                                    >
                                        <button onClick={handlePrevPage} className="paginationArrow"
                                            disabled={currentPage === 1}
                                        >
                                            <ArrowBackIosIcon />
                                        </button>
                                        {pageNumbers.map((pageNumber) => (
                                            <Pagination.Item
                                                key={pageNumber}
                                                onClick={() => handlePageChange(pageNumber)}
                                                className={pageNumber === currentPage ? "activePage" : "paginationItem"}
                                            >
                                                {pageNumber}
                                            </Pagination.Item>
                                        ))}
                                        <button onClick={handleNextPage}
                                            className="paginationArrow"
                                            disabled={currentPage === totalPages}
                                        >
                                            <ArrowForwardIosIcon />
                                        </button>
                                    </Pagination>
                                </div>
                            )}
                        </div>
                    </div>

                </section>
                
            <section className={style.bottom_hero_section}>
                <BottomHero HeroImage={BottomHeroImage} />
            </section>

                <ToastContainer />
            </div>
        </>
    )
}