
import { useState, useEffect } from 'react';
import style from './message.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/commonFunctions';
import { API_BASE_URL } from "../../config/config";

export default function Sucessmessage() {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderresponseData = location.state?.responseData;
  const [OrderData, setOrderData] = useState([]);
  const [OrdersData, setOrdersData] = useState([]);

  // Fetch the orderNo from query parameters
  const queryParams = new URLSearchParams(location.search);
  const orderNo = queryParams.get('order_no');

  // Fetch order data if orderNo exists
  useEffect(() => {
    if (orderNo) {
      fetch(`${API_BASE_URL}/productOrder/oderdetail/${orderNo}`)
        .then(response => response.json())
        .then(orderData => {
          setOrderData(orderData.data.orders); // Update state with fetched order data
          console.log('Full Order Data:', orderData); // You can log to inspect
        })
        .catch(error => {
          console.error('Error fetching order data:', error); // Handle any errors
        });
    }
  }, [orderNo]); // Effect runs when `orderNo` changes

  useEffect(() => {
    if (orderNo) {
      fetch(`${API_BASE_URL}/productOrder/orderdetail/${orderNo}`)
        .then(response => response.json())
        .then(orderData => {
          setOrdersData(orderData.data.orders); // Update state with fetched order data (assumed to be an array)
        })
        .catch(error => {
          console.error('Error fetching order data:', error); // Handle any errors
        });
    }
  }, [orderNo]);

  useEffect(() => {
    console.log('Updated Order Data:', OrdersData);
  }, [OrdersData]);

  const handlePrint = () => {
    const responseData = orderNo ? OrderData : OrderresponseData;
    navigate('/Receiptpage', { state: { responseData,OrdersData,orderNo, print: true } });
  };

  return (
    <div className={style.main_wrapper}>
      <div className={style.thanku_message_text}>
        <h1>Thank you for your purchase!</h1>
        <p>
          We sent an email to <a href='#'>{OrderresponseData?.user?.email_address}</a> with your order confirmation and receipt. 
          If the email hasn’t arrived within 3 minutes, please check your spam folder to see if the email was routed there.
        </p>
        <div className={style.time_place}>
          <div>
            <i className="fa-regular fa-clock"></i>
          </div>
          <div className={style.thanku_message_box_wrap} style={{ cursor: 'pointer' }} onClick={handlePrint}>
            <i className="fa-solid fa-print"></i>
            <h6>Print</h6>
          </div>
        </div>

        <div className={style.shopping_details_boxes}>
          <div className={style.shopping_details_boxes_lists}>
            <div>
              <img src="/assets/images/Icons_Filled.png" alt="" />
            </div>
            <div>
              <h3>Shipping Details</h3>
            </div>
           
            <div>
                {orderNo ? (
                <ul>
                    <li>{OrderData?.firstname} <span>{OrderData?.lastname}</span></li>
                    <li>{OrderData?.shippingAddress?.city?.title}</li>
                    <li>{OrderData?.email_address}</li>
                    <li>{OrderData?.phonenumber}</li>
                    <li>{OrderData?.shippingAddress?.address}</li>
                    <li>
                    {OrderData?.shippingAddress?.country} <span>{OrderData?.shippingAddress?.zipCode}</span>
                    <span>{OrderData?.shippingAddress?.city?.title}</span>
                    </li>
                </ul>
                ) : (
                <ul>
                    <li>{OrderresponseData?.user?.first_name} <span>{OrderresponseData?.user?.last_name}</span></li>
                    <li>{OrderresponseData?.shippingAddress?.city?.title}</li>
                    <li>{OrderresponseData?.user?.email_address}</li>
                    <li>{OrderresponseData?.shippingAddress?.phonenumber}</li>
                    <li>{OrderresponseData?.shippingAddress?.address}</li>
                    <li>
                    {OrderresponseData?.shippingAddress?.country} <span>{OrderresponseData?.shippingAddress?.zipCode}</span>
                    <span>{OrderresponseData?.shippingAddress?.city?.title}</span>
                    </li>
                </ul>
                )}
            </div>



          </div>
          <div className={style.shopping_details_boxes_lists}>
            <div>
              <img src="/assets/images/Frame 575.png" alt="" />
            </div>
            <div>
              <h3>Shipping Method</h3>
            </div>
            <div>
              <ul>
                <li>Preferred Method:</li>
                <li>FedEx (2-3 weeks delivery use for I-IAE only)</li>
              </ul>
            </div>
          </div>
          <div className={style.shopping_details_boxes_lists}>
            <div>
              <img src="/assets/images/Frame 575 (1).png" alt="" />
            </div>
            <div>
              <h3>Order Summary</h3>
            </div>
            <div>
                {orderNo ? (
                <ul className={style.shopping_details_prices_lists}>
                    <li>Subtotal: <span>{(OrderData?.subTotal || 0)} SAR</span></li>
                    <li>Discount: <span>{(OrderData?.discountPrice || 0)} SAR</span></li>
                    <li>Shipping Cost: <span>{(OrderData?.shippingCost || 0)} SAR</span></li>
                    <li>VAT: <span>{(OrderData?.vat || 0)} SAR</span></li>
                    <p>Total: <span>{(OrderData?.total || 0)} SAR</span></p>
                </ul>
                ) : (
                <ul className={style.shopping_details_prices_lists}>
                    <li>Subtotal: <span>{(OrderresponseData?.totalProductPrice?.subTotal || 0)} SAR</span></li>
                    <li>Discount: <span>{(OrderresponseData?.totalProductPrice?.discountPrice || 0)} SAR</span></li>
                    <li>Shipping Cost: <span>{(OrderresponseData?.totalProductPrice?.shippingCost || 0)} SAR</span></li>
                    <li>VAT: <span>{(OrderresponseData?.totalProductPrice?.vat || 0)} SAR</span></li>
                    <p>Total: <span>{(OrderresponseData?.totalProductPrice?.total || 0)} SAR</span></p>
                </ul>
                )}
          </div>
          </div>
        </div>

        <div>
        <Link to="/Receiptpage" state={{ responseData: orderNo ? OrderData : OrderresponseData,OrdersData , orderNo:orderNo }}>
           <button className={style.continue_btn}>Continue shopping</button>
        </Link>
        </div>
      </div>
    </div>
  );
}