import React, { useState, useEffect , useRef } from 'react';
// import { Select, Stack } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import style from './Shoppingcart.module.css';
import { useSearchParams, useNavigate } from 'react-router-dom';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import Loader from '../../components/loader/Loader';
import emptyImage from '../../assets/images/no-product-found1.png'
import { toast } from 'react-toastify';
import { API_BASE_URL, IMG_URL } from '../../config/config';
import { updateCartCount } from '../../utils/commonFunctions';
// import { applyCoupon } from '../../../../anycarpart-admin/controllers/api/productOrder/productOrder';
import NodDataImg from '../../assets/images/datanotfoundImg.png'
import AddToWishlistButton from '../../components/favoriteProducts/AddToWishlistButton';
// import getFetchConfig from '../../utils/getFetchConfig';
//import { API_BASE_URL, IMG_URL } from '../../config/config';



export default function Shoppingcart({ productId = null, wishDetail = null, subcategoryId = null, productTitle, productPrice, productCurrency, productThumbnailShort, productSku, setcartCount, cartWishCount, setCartWishCount, cartCount, quantity, product, setQuantity }) {
    const [searchParams, setSearchParams] = useSearchParams();
    // const categoryId = searchParams.get('categoryId');
    // const subCategoryId = searchParams.get('subCategoryId');
    const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
    const imageURL = `https://admin-anycarpart.go-demo.com`;
    //const fetchConfig = getFetchConfig();
    const [loader, setLoader] = useState(true);
    const [allCartProduct, setAllCartProduct] = useState([]);
    const [cartOrderSummary, setCartOrderSummary] = useState({ subTotal: 0, proDiscount: 0,discount: 0, shippingCost: 0, vat: 0, total: 0, afterDiscount: 0 });
    const [applyCoupon, setapplyCoupon] = useState()
    const [message, setmessage] = useState()
    const [totalProductPrice, settotalProductPrice] = useState()
    const [promotionDiscount, setPromotionDiscount] = useState(null);
    const navigate = useNavigate();
    const Cart = window.localStorage.getItem('ProductCart')
    const CartProduct = Cart ? JSON.parse(Cart) : [];
    const wishList = window.localStorage.getItem('Product')
    const wishListProduct = wishList ? JSON.parse(wishList) : []
    const tokenExists = window.localStorage.getItem("token") 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messageModal, setMessageModal] = useState('');
    const [file, setFile] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
        // const [email, setEmail] = useState('');
    const fetchConfig = getFetchConfig();
    
    const modalStyles = {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: isModalOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        zIndex: 1000, // Ensure it stays on top
      };
    
      const modalContentStyles = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      };
    
      const inputStyles = {
        width: '100%',
        padding: '10px',
        margin: '8px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
      };
    
      const buttonStyles = {
        padding: '10px 20px',
        margin: '10px 5px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
      };
    
      const submitButtonStyles = {
        ...buttonStyles,
        backgroundColor: '#007bff',
        color: 'white',
      };
    
      const cancelButtonStyles = {
        ...buttonStyles,
        backgroundColor: '#6c757d',
        color: 'white',
      };
       
    const fetchProfileData = () => {
            fetch(`${API_BASE_URL}/profile`, {
                ...fetchConfig,
                method: `GET`,
            })
                .then(handleFetchErrors)
                .then((dataprofile) => {
                    
                    setFirstName(dataprofile?.data?.user?.userInfo?.first_name);
                    setLastName(dataprofile.data.user.userInfo.last_name) ;                   
                    setEmail(dataprofile.data.user.userInfo.email_address);
                    
                })
                .catch((res)=>{
    
                });
        };

          useEffect(() => {
                 var x = localStorage.getItem("token");
            
                if (!x) navigate("/")
                if(x){
                    fetchProfileData()
                }
               
            }, [])


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // const handleSubmit = () => {
    //     // Assuming user info is available (from context or state)
    //    // const user = { _id: "user-id", email: "user@example.com" };
        
    //     const formData = new FormData();
    //     formData.append('messageModal', messageModal);
    //    // formData.append('file', file);
    //   //  formData.append('userId', user._id);
    //   ///  formData.append('userEmail', user.email);
    //   console.log(messageModal,'messageModal...')

    //     // Make an API request to submit the special quote
    //     console.log(formData,'formdata.......')
    //     fetch("http://localhost:3003/admin/request-quotes/store", {
    //         method: "POST",
    //         body:messageModal ,
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         alert("Special quote submitted successfully");
    //         closeModal(); // Close the modal and handle success
    //     })
    //     .catch(err => {
    //         alert("Error submitting special quote");
    //         console.error(err);
    //     });
    // };

    const handleSubmit = () => {
       // const formData = { messageModal };
       if (!messageModal) {
        alert("Please fill in all the fields.");
        return; // Stop the form submission if validation fails
      }

      if (file) {
         const allowedTypes = [
            "application/pdf",   // PDF
            "application/msword", // DOC
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
            "image/jpeg",         // JPG, JPEG
            "image/png"           // PNG
         ];

         if (!allowedTypes.includes(file.type)) {
            alert("Please upload a valid file (PDF, DOC, DOCX, JPG, PNG).");
            return; // Stop the form submission if the file is not one of the allowed types
         }
      }

        const formData = new FormData();
        formData.append('messageModal', messageModal);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        // formData.append('phoneNumber', phoneNumber);
        formData.append('email', email);
        if (file){
          formData.append('file', file);
        }
    
        fetch(`${API_BASE_URL}/requestQuote/store`, {
            method: "POST",
            body: formData, // Send the messageModal in JSON format
        })
        .then(response => response.json())
        .then(data => {
            alert("Special quote submitted successfully");
            setMessageModal('');   // Clear the message input
            setFirstName('');      // Clear the first name input
            setLastName('');       // Clear the last name input
         
            setEmail('');          // Clear the email input
             setFile(null);    
            // fileInputRef.current.value = '';      // Clear the file input
            closeModal();          // Close the modal after success
            window.location.reload();
        
        })
        .catch(err => {
            alert("Error submitting special quote");
            console.error(err);
        });
    };
    // const fileInputRef = useRef(null); // Create a ref for the file input element

   
    // <input
    //     type="file"
    //     accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
    //     ref={fileInputRef} // Attach the ref to the file input element
    //     onChange={(e) => setFile(e.target.files[0])}
    //  />
    
    const fetchAllCartProduct = () => {
        setLoader(true);
        fetch(`${API_BASE_URL}/cart/getAllCartProduct`, {
            ...fetchConfig,
            method: `POST`,
        })
            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
                const productsWithThumbnail = dataAllCartProduct.data.product.map(product => ({
                    ...product,
                    thumbnail: product.thumbnail ? `${IMG_URL}${product.thumbnail[0]}` : null
                }));
                console.log('productsWithThumbnail......', productsWithThumbnail)
                // console.log('---x----x----x----x----');
                console.log(dataAllCartProduct.data, "kommmm");
                setAllCartProduct(dataAllCartProduct.data.product);
                // setCartOrderSummary(dataAllCartProduct.data);
                setCartOrderSummary({ subTotal: dataAllCartProduct.data.subTotal,proDiscount:dataAllCartProduct.data.proDiscount ,discount: 0, shippingCost: dataAllCartProduct.data.shippingCost, vat: dataAllCartProduct.data.vat, total: dataAllCartProduct.data.total })
                setLoader(false);
            })
            .catch(standardFetchHandlers.error);
    };

    const updateCartPrduct = (quantity, productById) => {
        fetch(`${appURL}/cart/updateCart`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                id: productById,
                quantity: quantity
            })
        })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
                // window.location.reload();
                console.log(dataupdateCartPrduct.data, "mmmmmmmmmmmmmmmm");
                // // console.log('---x----x----x----x----')
                // // console.log(dataupdateCartPrduct.data);
                // fetchAllCartProduct();


            })
            .catch(standardFetchHandlers.error);
    };

    const removeCart = (productIdToRemove) => {
        if (tokenExists) {
            fetch(`${API_BASE_URL}/cart/remove/${productIdToRemove}`, {
                ...fetchConfig,
                method: `delete`,
            })
                .then((dataupdateCartPrduct) => {
                    toast.success("product remove from to cart sucessfully")
                    // fetchAllCartProduct()
                    fetchAllCartProduct()
                    updateCartCount(setcartCount, setCartWishCount);

                    // window.location.reload()
                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            const updatedProducts = CartProduct.filter(product => product._id !== productIdToRemove);
            setAllCartProduct(updatedProducts)
            const newProduct = JSON.stringify(updatedProducts)
            window.localStorage.setItem('ProductCart', newProduct)
            toast.success("product remove from to cart sucessfully")
            // window.location.reload()
            updateCartCount(setcartCount, setCartWishCount);

        }

    };
    const fetchActivePromotion = async () => {
            //setLoadingPromotion(true);
            try {
              const response = await fetch(`${API_BASE_URL}/promotions`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              const data = await response.json();
             
              if (data && data.data.promotionDiscount) {
                
                setPromotionDiscount(data.data.promotionDiscount); // Assuming the active promotion is stored in 'data.promotion'
              }
            } catch (error) {
              console.error('Error fetching active promotion:', error);
            } finally {
              //setLoadingPromotion(false);
            }
          };

    useEffect(() => {
     
        updateCartCount(setcartCount, setCartWishCount, setQuantity);

    }, [quantity]);

    useEffect(() => {
        
        //updateCartCount(setcartCount, setCartWishCount, setQuantity);
        fetchActivePromotion() ; 

    }, []);

    const incNum = (allproductByFilter, quantity) => {
        if (tokenExists) {
            console.log(allproductByFilter)
            fetch(`${API_BASE_URL}/cart/updateCart`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    id: allproductByFilter.productId,
                    quantity: allproductByFilter.quantity + 1,
                    add: "add",
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartPrduct) => {
                    // fetchAllCartProduct()
                    // window.location.reload()
                    fetchAllCartProduct()
                    updateCartCount(setcartCount, setCartWishCount, setQuantity);
                    console.log('kkkkkkkkkkk', allproductByFilter.quantity)

                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            let newNum = Number(quantity) + 1;
            let product = allCartProduct.filter((data) => data._id === allproductByFilter._id)
            for (let i = 0; i < CartProduct.length; i++) {
                if (CartProduct[i]._id === allproductByFilter._id) {
                    // If the product ID matches, update quantity and total price
                    CartProduct[i].quantity += 1;
                    CartProduct[i].price = CartProduct[i].price + (allproductByFilter.price / (CartProduct[i].quantity - 1));
                }
            }
            console.log(CartProduct, "CartProduct")
            let newproduct = JSON.stringify(CartProduct)
            window.localStorage.setItem('ProductCart', newproduct)
            // window.location.reload()
            updateCartCount(setcartCount, setCartWishCount, setQuantity);
         

        }
    };
    const decNum = (allproductByFilter, quantity) => {
        if (tokenExists) {
            console.log(allproductByFilter)
            fetch(`${API_BASE_URL}/cart/updateCart`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    id: allproductByFilter.productId,
                    quantity: allproductByFilter.quantity - 1,
                    add: "minus",
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartPrduct) => {
                    fetchAllCartProduct()
                    // window.location.reload()
                    updateCartCount(setcartCount, setCartWishCount, setQuantity);

                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            let newNum = Number(quantity) - 1;
            let product = allCartProduct.filter((data) => data._id === allproductByFilter._id)
            for (let i = 0; i < CartProduct.length; i++) {
                if (CartProduct[i]._id === allproductByFilter._id) {
                    // If the product ID matches, update quantity and total price
                    CartProduct[i].quantity -= 1;
                    CartProduct[i].price = CartProduct[i].price - (allproductByFilter.price / (CartProduct[i].quantity + 1));
                }
            }
            console.log(CartProduct, "CartProduct")
            let newproduct = JSON.stringify(CartProduct)
            window.localStorage.setItem('ProductCart', newproduct)
            // window.location.reload()
            updateCartCount(setcartCount, setCartWishCount, setQuantity);

        }

    }

    const tokenExits = window.localStorage.getItem('token')
    const [isHeartActive, setIsHeartActive] = useState(false);
    const updateFavoriteProduct = (event, product) => {
        event.stopPropagation();


        if (tokenExists) {
            fetch(`${API_BASE_URL}/product/favoriteProduct`, {
                ...fetchConfig,
                method: 'POST',
                body: JSON.stringify({
                    productId: product._id
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartProduct) => {
                    console.log(dataupdateCartProduct.data, "product added");
                    if (dataupdateCartProduct.data.message === "Something went wrong") {
                        toast.success("Product remove from wishlist.");
                    } else {
                        toast.success("Product added to wishlist.");
                    }
                    // Update cart count
                    updateCartCount(setcartCount, setCartWishCount);
                })
                .catch((error) => {
                    console.log(error.message);
                });
        } else {
            let data = window.localStorage.getItem("Product");
            if (!data) {
                const newProduct = JSON.stringify([product]);
                window.localStorage.setItem("Product", newProduct);
                setIsHeartActive(true);
                toast.success("Product added to wishlist.");
            } else {
                const existingProducts = JSON.parse(data);
                const isProductInWishlist = existingProducts.filter((item) => item._id === product._id);
                if (isProductInWishlist.length === 0) {
                    existingProducts.push(product);
                    const newProduct = JSON.stringify(existingProducts);
                    window.localStorage.setItem("Product", newProduct);
                    setIsHeartActive(true);
                    toast.success("Product added to wishlist.");
                } else {
                    setIsHeartActive(true);
                    toast.success("Product is already in your wishlist.");
                }
            }
            // Update cart count
            updateCartCount(setcartCount, setCartWishCount);
        }
    };


    useEffect(() => {
        setAllCartProduct(CartProduct)
        let price = 0
        for (let i = 0; i <= CartProduct.length - 1; i++) {
            console.log(CartProduct[i].price, "newlydata")
            price += CartProduct[i].price
        }
        console.log(price, "priceprice")
        let subTotal = price;
        let vats = subTotal * 0.15;
        let Total = subTotal + (subTotal * 0.15)
        setCartOrderSummary({
            subTotal: subTotal,
            discount: 0,
        
            shippingCost: 0,
            vat: vats,
            total: Total,
            afterDiscount: 0
        });
    }, []);

    const redirectToProductDetails = (subcategoryId, productId) => {
        if (localStorage.getItem('token')) {
            navigate({
                pathname: '/product-details',
                search: `?subcategoryId=${subcategoryId}&productId=${productId}`,
            });
        }
    };


    const proceedCheckout = () => {
        let token = window.localStorage.getItem('token')
        if (!token) {
            toast.warning("please login and proceed the checkout")
        } else {
            navigate('/Checkoutshipping')
        }

    }

    const applyCouponHanler = () => {
        window.localStorage.setItem('coupon', applyCoupon)
        fetch(`${appURL}/productOrder/applyCoupon`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify({
                couponCode: applyCoupon,
            }),
        })
            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
              
                setmessage(dataAllCartProduct.data.message);
                if (dataAllCartProduct.status === 200) {
                    setmessage('Coupon Applied');
              
                    setCartOrderSummary({
                        subTotal: dataAllCartProduct.data.totalProductPrice.subTotal,
                        discount: dataAllCartProduct.data.totalProductPrice.discount,
                        shippingCost: dataAllCartProduct.data.totalProductPrice.shippingCost,
                        vat: dataAllCartProduct.data.totalProductPrice.vat,
                        total: dataAllCartProduct.data.totalProductPrice.total,
                        afterDiscount: dataAllCartProduct.data.totalProductPrice.total
                    });
                    settotalProductPrice(dataAllCartProduct.data.totalProductPrice);
                }
                // console.log('---x----x----x----x----');
                // console.log(dataAllCartProduct.data.product, 'hhh');
                // setAllCartProduct(dataAllCartProduct.data.product);
                // setCartOrderSummary(dataAllCartProduct.data);
            })
            .catch(standardFetchHandlers.error);
    };
    useEffect(() => {
        if (tokenExists) {
            fetchAllCartProduct()
        }
    }, [tokenExists])

    const [productsLoaded, setProductsLoaded] = useState(false);


    return (
        <div className={style.main_wrapper}>
            <div className={style.shoping_cart}>
                <h1>Shopping Cart</h1>
                <p>{allCartProduct.length} item(s) in Cart</p>
            </div>

            <div className={style.shoping_cart_tabs}>
                <div className={style.shoping_cart_tabs_lists}>
                    <div>
                        <p>Product</p>
                    </div>
                    <div>
                        <p>Description</p>
                    </div>
                </div>
                <div className={style.shoping_cart_tabs_lists2}>
                    <div>
                        <p>Price</p>
                    </div>
                    <div>
                        <p>Quantity</p>
                    </div>
                    <div>
                        <p>Total</p>
                    </div>
                </div>
            </div>
            {loader && <Loader />}
            {
                allCartProduct.map((allproductByFilter) => {
                    return (

                        <div className={style.shoping_cart_items}>
                            <div className={style.shoping_cart_tabs_lists_filter}>
                                <div className={style.shoping_cart_item_filters}>
                                    {/* <img src="/assets/Rectangle 114 (1).png" alt="" /> */}
                                    <img src={allproductByFilter.thumbnail ? `${IMG_URL}${allproductByFilter.thumbnail[0]}` : `/assets/images/break.png`} alt=""
                                        onClick={() => redirectToProductDetails(allproductByFilter.id, allproductByFilter.productId)}
                                    />
                                    <h5 onClick={() => redirectToProductDetails(allproductByFilter.id, allproductByFilter.productId)}>{allproductByFilter.title}</h5>
                                </div>
                                <div className={style.shoping_cart_description}>
                                    <h4>{allproductByFilter.description}</h4>
                                    {/* <p>Rear brake pads</p> */}
                                </div>
                            </div>

                            <div className={style.shoping_cart_tabs_lists_product} >
                                <div className={style.shoping_cartprice_mark}>
                                    <p>{allproductByFilter.productPrice}<span> SAR</span></p>
                                </div>
                                <div>

                                    <button style={{ backgroundColor: '#FFFFFF' }} className={style.increamnet_decreament_btn}>
                                        {/* <span
                                    onClick={() => decNum(allproductByFilter, allproductByFilter.quantity)}
                                    disabled={allproductByFilter.quantity === 1}
                                    className={allproductByFilter.quantity === 1 ? style.disabled : ''}
                                > */}
                                        <span
                                            onClick={() => {
                                                console.log("Current Quantity:", quantity); // Log current quantity
                                                // if (quantity > 1) {
                                                decNum(allproductByFilter, allproductByFilter.quantity); // Only call decNum if quantity is more than 1
                                                // }
                                            }}
                                            className={quantity === 1 ? style.disabled : ''}
                                            style={{ pointerEvents: quantity === 1 ? 'none' : 'auto' }}
                                        >
                                            -
                                        </span>

                                        <span>{allproductByFilter.quantity}</span>
                                        <span onClick={() => incNum(allproductByFilter, allproductByFilter.quantity)}>+</span>
                                    </button>


                                </div>

                                <div className={style.orange_text}>
                                    <p>{allproductByFilter.price.toFixed(2)}<span>SAR</span></p>
                                </div>
                                <div className={style.wishlist_icons}>
                                    <div className={style.cart_icon}>
                                        {/* {
                                            allproductByFilter.isFavorite === 0 ? (
                                                
                                                <i onClick={(event) => updateFavoriteProduct(event, allproductByFilter.id)} className="fa-regular fa-heart"></i>
                                            ) : (
                                                <i onClick={(event) => updateFavoriteProduct(event, allproductByFilter.id)} className="fa-solid fa-heart" style={{ color: '#c50e0e' }}></i>
                                            )
                                        } */}
                                        <AddToWishlistButton
                                            productDetails={allproductByFilter}
                                            setcartCount={allproductByFilter.setcartCount}
                                            cartWishCount={allproductByFilter.cartWishCount}
                                            setCartWishCount={allproductByFilter.setCartWishCount}
                                            productId={allproductByFilter.productId} // Pass productId as a prop
                                        />

                                        {/* <img src="/assets/heart.png" alt="" /> */}
                                        <i onClick={() => { removeCart(allproductByFilter.id) }} className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                })
            }
            {allCartProduct.length > 0 ? (
                <div className={style.shoping_cart_list_products}>
                    <div className={style.coupan_code_box} >
                        <div >
                            <h2>Coupon Code</h2>
                            <p>Enter your coupon code if you have one.</p>
                        </div>
                        <div className={style.form_box}>
                            <input type="text" placeholder="Enter your coupon code" onChange={(event) => { setapplyCoupon(event.target.value) }} readOnly={message === "Coupon Applied"} />
                            <button onClick={applyCouponHanler} disabled={message === "Coupon Applied"}>
                                {message === "Coupon Applied" ? "Applied" : "Apply"}
                            </button>

                        </div>

                        {/* <div>
                            {message && (
                                <span style={{ color: message === "Invalid coupon code" ? 'red' : 'green' }}>
                                    {message}
                                </span>
                            )}
                            <span>{message === "Coupon Applied" ? <span>You saved SAR {totalProductPrice.discount.toFixed(2)} with this coupon code</span> : null}</span>

                        </div> */}
                        <div>
                            {message && (
                                <>
                                    <span style={{ color: message === "Invalid coupon code" ? 'red' : 'green' }}>
                                        {message}
                                    </span>
                                    <br />
                                    {message === "Coupon Applied" && (
                                        <div
                                            style={{
                                                backgroundColor: '#e0ffff',
                                                color: 'black',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid green',
                                                marginTop: '10px',

                                            }}
                                        >
                                            You saved SAR <strong>{totalProductPrice.discount.toFixed(2)}</strong> with this coupon code
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div>
      {/* Trigger the Modal */}
      <h2>Do you want to send special quote?</h2>
      <button onClick={openModal} style={buttonStyles}>
       Special Quote  Request 
      </button>

      {/* Modal */}
      <div style={modalStyles}>
        <div style={modalContentStyles}>
          <h2>Special Quote Request</h2>
          <label>
            Message:
            <textarea
              value={messageModal}
              onChange={(e) => setMessageModal(e.target.value)}
              style={{ ...inputStyles, height: '100px' }}
            />
          </label>
          <label>
            Upload Pdf , Doc , Image:
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
              style={inputStyles}
            />
          </label>

          <div>
            <button onClick={closeModal} style={cancelButtonStyles}>
              Cancel
            </button>
            <button onClick={handleSubmit} style={submitButtonStyles}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

                    </div>

                    <div className={style.order_summary_section}>
                        <div>
                            <h2 className={style.order_summary_heading}>Order Summary</h2>
                        </div>
                        {/* <div className={style.order_summary}>
                            <div className={style.order_summary_lists}>
                                <p>Sub Total:</p>
                                {/* <p>Discount</p>
                        <p>Estimated Shipping Cost:</p> 
                                <p>VAT:</p>
                                <h2>Total:</h2>
                            </div>
                            <div className={style.order_summary_lists1}>
                                <p style={{ textAlign: 'right' }}>{cartOrderSummary.subTotal.toFixed(2)}SAR</p>
                                {/* <p style={{ textAlign: 'right' }}>{!cartOrderSummary.discount ? 0 : `(${cartOrderSummary.subTotal}-${cartOrderSummary.discount})=${cartOrderSummary.afterDiscount.toFixed(2)}`} SAR</p>
    <p style={{ textAlign: 'right' }}>{cartOrderSummary.shippingCost} SAR</p> 
                                <p style={{ textAlign: 'right' }}>{cartOrderSummary.vat.toFixed(2)}SAR</p>
                                <h2 style={{ textAlign: 'right' }}>{cartOrderSummary.total.toFixed(2)}SAR</h2>
                            </div>

                                            </div> */}
                        <div className={style.order_summary}>
                        <div className={style.order_summary_lists}>
                            <p>Sub Total:</p>
                            {promotionDiscount && <p>Discount({promotionDiscount}%): </p>} {/* Display Discount if it exists */}
                            <p>VAT(15%):</p>
                            <h2>Total:</h2>
                        </div>
                        <div className={style.order_summary_lists1}>
                            <p style={{ textAlign: 'right' }}>{cartOrderSummary.subTotal.toFixed(2)}SAR</p>
                            
                            {/* Display Discount only if proDiscount exists */}
                            {promotionDiscount && (
                                <p style={{ textAlign: 'right' }}>
                                    {/* -{cartOrderSummary.proDiscount.toFixed(2)} SAR */}
                                    {(cartOrderSummary.subTotal*promotionDiscount/100).toFixed(2)} SAR

                                </p>
                            )}
                            
                            <p style={{ textAlign: 'right' }}>{((cartOrderSummary.subTotal- cartOrderSummary.subTotal*promotionDiscount/100)*0.15).toFixed(2)}SAR</p>
                            
                            {/* Calculate Total after applying Discount */}
                            <h2 style={{ textAlign: 'right' }}>
                                {promotionDiscount 
                                    ? ((cartOrderSummary.subTotal - cartOrderSummary.subTotal*promotionDiscount/100)+((cartOrderSummary.subTotal- cartOrderSummary.subTotal*promotionDiscount/100)*0.15)).toFixed(2) 
                                    : (cartOrderSummary.total.toFixed(2))} 
                                SAR
                            </h2>
                        </div>
                    </div>

                        <button onClick={proceedCheckout}>Proceed to checkout</button>
                    </div>
                </div>
            ) : (
                <div style={{ textAlign: "center" }}>
                    <img src={NodDataImg} width="50%" alt="" />
                </div>
            )}
        </div>
    )
}
