const API_BASE_URL = 'https://admin-anypart.go-globe.dev/api/v1';
// const API_BASE_URL = 'http://localhost:3003/api/v1';
 //const IMG_URL = 'http://localhost:3003/';
// const API_BASE_URL = 'https://js-projects-dashboard-anycarpart.0yxzv7.easypanel.host/api/v1';
 const IMG_URL = 'https://admin-anypart.go-globe.dev/';
const API_BASE_ADMIN = 'https://admin-anypart.go-globe.dev';
export { API_BASE_URL, IMG_URL, API_BASE_ADMIN };

// export default API_BASE_URL;

