import React, { useEffect } from 'react';
import style from './Receipt.module.css';
import { Link, useLocation } from 'react-router-dom';

export default function Receiptpage() {
 
    const location = useLocation();
    const orderNo = location.state?.orderNo ; 
    const OrderresponseData = location.state?.responseData;
    const OrderData = location.state?.responseData;
    const OrdersData = location.state?.OrdersData;
    const print = location.state?.print;

    useEffect(() => {
        if (print) {
            window.print();
        }
    }, [print]);
    return (
        <div className={style.main_wrapper}>
            <div className={style.bill_receipt_wrap}>
                <div className={style.bill_receipt_wrap_img} >
                <img src="/assets/Logo-design.svg" alt="Logo Image" />
                </div>
                <div className={style.bill_receipt_wrap_text}>
                    <h1>Business Invoice</h1>
                </div>
            </div>                    
            <div className={style.receipt_address}>
                <div className={style.receipt_address_location}>
                    <div className={style.receipt_address_location_items}>
                        <img src="/assets/Frame 530.png" alt="" />
                        <p>Khurais Road, Riyadh,
                            Saudi Arabia.</p>
                    </div>
                    <div className={style.receipt_address_location_items}>
                        <img src="/assets/Group 530.png" alt="" />
                        <p>+966 386-647-3637
                        </p>
                    </div>
                    <div className={style.receipt_address_location_items}>
                        <img src="/assets/Group 530 (1).png" alt="" />
                        <p>contact@gulfmotors.sa
                        </p>
                    </div>
                </div>
                <div className={style.receipt_input_fields_wrap}>
                    <div className={style.col_3}>
                        <input className={style.effect_1} type="text" placeholder="Invoice No." />
                        <span className={style.focus_border}></span>
                    </div>
                    <div className={style.col_3}>
                        <input className={style.effect_1} type="text" placeholder="Date" />
                        <span className={style.focus_border}></span>
                    </div>
                    <div className={style.col_3}>
                        <input className={style.effect_1} type="text" placeholder="Cust. Id " />
                        <span className={style.focus_border}></span>
                    </div>
                    <div className={style.col_3}>
                        <input className={style.effect_1} type="text" placeholder="Terms " />
                        <span className={style.focus_border}></span>
                    </div>
                </div>
            </div>
            <div className={style.receipt_shipped_address}>
                <div className={style.shopping_details_boxes_lists}>
                    <div>
                        <h3>Bill to </h3>
                    </div>
               
                    <div>
                    {orderNo ? (
                    <ul>
                        <li>{OrderData?.firstname} <span>{OrderData?.lastname}</span></li>
                        <li>{OrderData?.shippingAddress?.city?.title}</li>
                        <li>{OrderData?.email_address}</li>
                        <li>{OrderData?.phonenumber}</li>
                        <li>{OrderData?.shippingAddress?.address}</li>
                        <li>
                        {OrderData?.shippingAddress?.country} <span>{OrderData?.shippingAddress?.zipCode}</span>
                        <span>{OrderData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    ) : (
                    <ul>
                        <li>{OrderresponseData?.user?.first_name} <span>{OrderresponseData?.user?.last_name}</span></li>
                        <li>{OrderresponseData?.shippingAddress?.city?.title}</li>
                        <li>{OrderresponseData?.user?.email_address}</li>
                        <li>{OrderresponseData?.shippingAddress?.phonenumber}</li>
                        <li>{OrderresponseData?.shippingAddress?.address}</li>
                        <li>
                        {OrderresponseData?.shippingAddress?.country} <span>{OrderresponseData?.shippingAddress?.zipCode}</span>
                        <span>{OrderresponseData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    )}
               </div>
                </div>
                <div className={style.shopping_details_boxes_lists}>
                    <div>
                        <h3>Ship to </h3>
                    </div>
                    <div>
                    {orderNo ? (
                    <ul>
                        <li>{OrderData?.firstname} <span>{OrderData?.lastname}</span></li>
                        <li>{OrderData?.shippingAddress?.city?.title}</li>
                        <li>{OrderData?.email_address}</li>
                        <li>{OrderData?.phonenumber}</li>
                        <li>{OrderData?.shippingAddress?.address}</li>
                        <li>
                        {OrderData?.shippingAddress?.country} <span>{OrderData?.shippingAddress?.zipCode}</span>
                        <span>{OrderData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    ) : (
                    <ul>
                        <li>{OrderresponseData?.user?.first_name} <span>{OrderresponseData?.user?.last_name}</span></li>
                        <li>{OrderresponseData?.shippingAddress?.city?.title}</li>
                        <li>{OrderresponseData?.user?.email_address}</li>
                        <li>{OrderresponseData?.shippingAddress?.phonenumber}</li>
                        <li>{OrderresponseData?.shippingAddress?.address}</li>
                        <li>
                        {OrderresponseData?.shippingAddress?.country} <span>{OrderresponseData?.shippingAddress?.zipCode}</span>
                        <span>{OrderresponseData?.shippingAddress?.city?.title}</span>
                        </li>
                    </ul>
                    )}
               </div>
                </div>
            </div>
            <div className={style.receipt_table_box}>
            {/* <table>
                    <thead>
                        <tr>
                            <th>Item No.</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                      
                        
                        {OrderresponseData?.orderDetails?.map((item, index) => (
                            <tr key={item.orderId}>
                                <td>{item.productId}</td>
                                <td>{item.productDescription.slice(0, 50)}{item.productDescription.length > 50 ? '...' : ''}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price.toFixed(2)}</td>
                                <td>{item.total.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
            </table> */}
            <table>
                <thead>
                    <tr>
                    <th>Item No.</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {orderNo ? (
                    OrdersData?.map((item, index) => (
                        <tr key={item.orderId}>
                        <td>{item.productId}</td>
                        <td>
                            {item.productDescription.slice(0, 50)}
                            {item.productDescription.length > 50 ? '...' : ''}
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.price.toFixed(2)}</td>
                        <td>{((item.price*item.quantity)+(item.price*item.quantity)*15/100).toFixed(2)}</td>
                        </tr>
                    ))
                    ) : (
                    OrderresponseData?.orderDetails?.map((item, index) => (
                        <tr key={item.orderId}>
                        <td>{item.productId}</td>
                        <td>
                            {item.productDescription.slice(0, 50)}
                            {item.productDescription.length > 50 ? '...' : ''}
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.price.toFixed(2)}</td>
                        <td>{((item.price*item.quantity)+(item.price*item.quantity)*15/100).toFixed(2)}</td>
                        </tr>
                    ))
                    )}
                </tbody>
                </table>

            </div>
            <div className={style.shopping_details_boxes_lists}>
                <div>
                    <h3>Order Summary</h3>
                </div>
                <div>
                {orderNo ? (
                <ul className={style.shopping_details_prices_lists}>
                    <li>Subtotal: <span>{(OrderData?.subTotal || 0).toFixed(2)} SAR</span></li>
                    <li>Discount: <span>{(OrderData?.discountPrice || 0).toFixed(2)} SAR</span></li>
                    <li>Shipping Cost: <span>{(OrderData?.shippingCost || 0).toFixed(2)} SAR</span></li>
                    <li>VAT: <span>{(OrderData?.vat || 0).toFixed(2)} SAR</span></li>
                    <p>Total: <span>{(OrderData?.total || 0).toFixed(2)} SAR</span></p>
                </ul>
                ) : (
                <ul className={style.shopping_details_prices_lists}>
                    <li>Subtotal: <span>{(OrderresponseData?.totalProductPrice?.subTotal || 0).toFixed(2)} SAR</span></li>
                    <li>Discount: <span>{(OrderresponseData?.totalProductPrice?.discountPrice || 0).toFixed(2)} SAR</span></li>
                    <li>Shipping Cost: <span>{(OrderresponseData?.totalProductPrice?.shippingCost || 0).toFixed(2)} SAR</span></li>
                    <li>VAT: <span>{(OrderresponseData?.totalProductPrice?.vat?.toFixed(2) || 0)} SAR</span></li>
                    <p>Total: <span>{(OrderresponseData?.totalProductPrice?.total || 0).toFixed(2)} SAR</span></p>
                </ul>
                )}
          </div>
        </div>
            <div className={style.signature_wrap_box}>
                <div class="flex">
                    <div className={style.sigbox} id="">
                        <input name="signature" type="text" placeholder="" />
                        <p>Authorized Sign</p>
                    </div>
                </div>
                <div class="flex">
                    <div className={style.sigbox} id="">
                        <input name="signature" type="text" placeholder="" />
                        <p> Client Sign</p>
                    </div>
                </div>
            </div>
         
        </div>
    )
}
